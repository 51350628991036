<template>
  <Loader
    :value="promises.fetch"
    :key="$path('title', post)"
    :class="`post-page post-page--${$path('slug', post)}`"
  >
    <Blocks v-if="item.posts" :blocks="config" :custom-components="customComponents" />
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import CustomComponents from '@/components/Custom'
import API from '@/api'

import Config from '../../config/posts'
import { Blocks } from '@kvass/pagebuilder'
import BrowserApiMixin from '@/mixins/browser-api'
import TravelCalculator from '@kvass/travel-calculator'

export default {
  mixins: [BrowserApiMixin('post')],
  props: {
    slug: {
      type: String,
      default: 'post',
    },
  },
  data() {
    return {
      flatfinders: [],
      options: {
        displayTravelCalc: false,
      },
    }
  },
  watch: {
    post: {
      handler() {
        this.getFlatfinder()
      },
    },
  },
  computed: {
    ...mapState('Root', ['promises', 'item']),
    config() {
      const sources = this.post.customFieldsConfig?.map(schema => schema.source) || ['subpage']
      return Config[sources[0]].call(this, { flatfinders: this.flatfinders })
    },
    post() {
      if (!this.item.posts) return {}
      return this.item.posts.find(p => p.slug === this.slug)
    },
    customComponents() {
      return { ...CustomComponents, TravelCalculator }
    },
    flatfinderId() {
      return this.$path('customFields.flatfinder.id', this.post)
    },
  },

  methods: {
    getFlatfinder() {
      if (!this.flatfinderId) return Promise.resolve()
      API.getFlatfinder(this.flatfinderId).then(data => {
        window.sessionStorage.setItem('flatfinder_current', JSON.stringify(data))
        return (this.flatfinders = [data])
      })
    },
  },

  mounted() {
    this.promises.fetch.then(() => this.getFlatfinder())
  },

  metaInfo() {
    return {
      title: this.$path('title', this.post),
    }
  },

  components: {
    Blocks,
  },
}
</script>

<style lang="scss">
.post-page {
  & > .kpb-blocks {
    @for $i from 1 through 15 {
      & > *:nth-child(#{$i}) {
        order: $i * 10;
      }
    }
  }

  .section-info {
    @include respond-below('phone') {
      padding-top: 1rem;
    }
  }

  .travel-calc {
    display: none;
  }
}
</style>
