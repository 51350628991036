import { TransformTag } from '@/utils'
import API from '@/api'

export default function() {
  const get = (path, source = 'Root.item.posts') => {
    const slug = this.$path('_props.slug', this)
    if (!slug) return

    let data = this.$path(source, this.$store.state) || []
    if (source.includes('posts')) {
      data = data.find(p => p.slug === slug)
      if (!data) return
    }

    return this.$path(path, data)
  }
  return [
    {
      condition: get('media.cover.length') || get('content'),
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-info',
        class: 'section-info',
        style: 'font-size: 1.25rem;',
      },
      blocks: {
        default: [
          {
            component: 'Grid',
            props: {
              gap: '3rem',
              columns: '1',
            },
            blocks: {
              default: [
                {
                  component: 'Slider',
                  data: get('media.cover'),
                  condition: get('media.cover.length'),
                },
                {
                  component: 'Text',
                  data: {
                    content: TransformTag(get('content')),
                  },
                  condition: get('content'),
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('customFields.article-portal.max-items') === 'none' ? false : true,
      props: {
        width: 'medium',
      },
      blocks: {
        default: [
          {
            component: 'Portal',
            variant: 'article',
            props: {
              api: API,
              filterMethod: get('customFields.article-portal.filterMethod') || 'county',
              filterCategories:
                get('customFields.article-portal.filterMethod') === 'category'
                  ? get('customFields.article-portal.categories') || []
                  : [],
              theme: 'card',
              maxItems: parseInt(get('customFields.article-portal.max-items')) || 50,
            },
            blocks: {
              top: [
                {
                  condition: get('customFields.article-portal.content'),
                  component: 'Text',
                  data: {
                    content: get('customFields.article-portal.content'),
                  },
                  props: {
                    class: 'text--small',
                    style: 'padding-bottom: 3rem;',
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
